<template>
  <div>
    <button @click="toggleModal" data-cy="delete-session-btn">
      Delete
    </button>
    <SimpleModal v-if="deleteModal" @close="deleteModal = false">
      <div class="text-center">
        <p class="mb-4">Are you sure you want to delete this session?</p>
        <button
          class="text-center"
          :disabled="loading"
          @click="deleteSession"
          data-cy="do-it-btn"
        >
          <span v-if="loading">{{ loadingMessage }}</span>
          <span v-else>Do It</span>
        </button>
        <p class="error" v-if="formError">{{ formError }}</p>
      </div>
    </SimpleModal>
  </div>
</template>

<script>
import SimpleModal from '@/components/UI/SimpleModal.vue';

export default {
  data () {
    return {
      deleteModal: false,
      formError: ''
    };
  },
  props: {
    session: Object
  },
  components: {
    SimpleModal
  },
  computed: {
    loading () {
      return this.$store.getters.loading;
    },
    loadingMessage () {
      return this.$store.getters.loadingStatusMessage;
    }
  },
  methods: {
    toggleModal () {
      this.deleteModal = !this.deleteModal;
    },
    async deleteSession () {
      this.$store
        .dispatch('deleteSessionMutation', {
          sessionId: this.session.sessionId
        })
        .then((res) => {
          if (res.data) {
            this.$store.dispatch('getSessionsQuery');
            this.deleteModal = false;
            this.formError = '';
          } else {
            this.formError = res.errors[0].message;
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  }
};
</script>
