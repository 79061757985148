var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('p',{staticClass:"text-right"},[_vm._v(" Total Validations: "+_vm._s(_vm.validations.length)+" | Updated at: "+_vm._s(new Date().toLocaleString("en-US", { hour: "numeric", minute: "numeric", hour12: true }))+" ")]),_c('vue-good-table',{attrs:{"data-cy":"validations-table","title":"Validations Table","columns":_vm.validationsColumns,"rows":_vm.validations,"sort-options":{
        enabled: true,
        initialSortBy: {field: 'time', type: 'desc'}
      },"search-options":{
        enabled: true,
        initialSortBy: { field: 'time', type: 'desc' }
      },"line-numbers":true},on:{"on-cell-click":_vm.onCellClick},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'data.deviceId')?_c('a',{attrs:{"href":"#","data-cy":"device-detail-link"}},[_vm._v(_vm._s(props.row.data.deviceId))]):(props.column.type === 'id')?_c('div',{staticClass:"min-w-220"},[_vm._v(" "+_vm._s(props.row[props.column.field])+" ")]):(props.column.type === 'number')?_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(props.row[props.column.field])+" ")]):(props.column.field === 'delete')?_c('div',[(_vm.sessionExists(props.row.data.sessionId))?_c('div',[(props.row.data.sessionId != '')?_c('DeleteSession',{attrs:{"session":{sessionId: props.row.data.sessionId}}}):_vm._e()],1):_vm._e()]):(props.column.type === 'AWSDateTime')?_c('TwoDates',{attrs:{"date":props.row[props.column.field]}}):_vm._e()]}}])},[_c('div',{staticClass:"emptystate",attrs:{"slot":"emptystate","data-cy":"empty-state"},slot:"emptystate"},[_c('EmptyTable',{attrs:{"loading":_vm.$store.state.loading,"type":"Validations"}})],1)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }