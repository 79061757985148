<template>
  <div class="text-center pt-2">
    <span
      v-if="colorHex"
      class="dot"
      v-bind:style="{ backgroundColor: colorHex }"
      v-bind:class="{ formDot: form }"
    />
    <span
      v-if="colorArray"
      class="dot"
      v-bind:style="{ backgroundColor: convertColorArrayToHex }"
      v-bind:class="{ formDot: form }"
    />
    <p>{{ column }}</p>
  </div>
</template>

<script>
export default {
  props: {
    colorHex: String,
    form: Boolean,
    colorArray: String,
    columnName: String
  },
  computed: {
    convertColorArrayToHex () {
      const column = this.columnName;
      const colorJSON = JSON.parse(this.colorArray);
      const array = colorJSON[column];
      const hex = this.fullColorHex(array[0], array[1], array[2]);
      return hex;
    },
    columnKey () {
      const colorJSON = JSON.parse(this.colorArray);
      const key = colorJSON[0];
      return [key];
    }
  },
  methods: {
    rgbToHex (rgbVal) {
      let hex = Number(rgbVal).toString(16);
      if (hex.length < 2) {
        hex = '0' + hex;
      }
      return hex;
    },
    fullColorHex (r, g, b) {
      var red = this.rgbToHex(r);
      var green = this.rgbToHex(g);
      var blue = this.rgbToHex(b);
      return '#' + red + green + blue;
    }
  }
};
</script>

<style lang="scss">
.dot {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: inline-block;
  background-color: white;
  border: solid 1px black;
}
.formDot {
  position: absolute;
  bottom: 27px;
  left: 0px;
}
</style>
