<template>
  <div>
    <h2 class="text-center font-bold uppercase mb-4 text-2xl">
      {{ deviceType }}: #{{ deviceId }}
    </h2>
    <div class="flex flex-wrap mb-14 justify-start">
      <div class="w-1/2">
        <p class="text-xs text-black">Status</p>
        <select v-model="status">
          <option
            v-bind:key="option.name"
            v-for="option in $store.getters.deviceStatuses"
            v-bind:value="option.name"
            >{{ option.name }}</option
          >
        </select>
      </div>
      <div class="w-1/2">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Features"
          placeholder="features"
          v-model="features"
          type="number"
        />
      </div>
      <div class="w-1/2">
        <p class="text-xs text-black">Log Level</p>
        <select v-model="logLevel">
          <option
            v-bind:key="option.name"
            v-for="option in $store.getters.braunLogLevel"
            v-bind:value="option.name"
            >{{ option.name }}</option
          >
        </select>
      </div>
    </div>
    <div class="text-center">
      <button @click="updateDevice" :disabled="loading">
        <span v-if="loading">{{ loadingMessage }}</span>
        <span v-else>Update Device</span>
      </button>
    </div>
    <p class="error" v-if="formError">{{ formError }}</p>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      deviceId: '',
      deviceType: '',
      features: '',
      logLevel: '',
      status: ''
    };
  },
  methods: {
    resetForm (data) {
      this.$store.dispatch('updateSelectedDevice', data);

      this.$store.dispatch('getDevicesQuery');

      this.$emit('refresh');
    },
    async updateDevice () {
      this.$store
        .dispatch('updateDeviceMutation', {
          device: {
            deviceId: this.deviceId,
            status: this.status,
            features: this.features,
            logLevel: this.logLevel
          }
        })
        .then(res => {
          if (res.data) {
            this.formError = '';

            this.resetForm(res.data.updateDevice);
          } else {
            this.formError = res.errors[0].message;
          }
        })
        .catch(() => {
          this.formError = 'Sorry - There was an error. Please try again.';
        });
    }
  },
  computed: {
    loading () {
      return this.$store.getters.loading;
    },
    loadingMessage () {
      return this.$store.getters.loadingStatusMessage;
    }
  },
  created () {
    this.$store.dispatch('updateDeviceStatuses');
    this.$store.dispatch('updateBraunLogLevel');
    if (
      this.$store.state.selectedDevice.deviceId !== this.$route.params.deviceId
    ) {
      this.$store.dispatch('setSelectedDevice', this.$route.params.deviceId);
    }

    const device = this.$store.state.selectedDevice;
    this.deviceId = device.deviceId;
    this.deviceType = device.deviceType;
    this.status = device.status;
    this.features = device.features;
    this.logLevel = device.logLevel;
  }
};
</script>
