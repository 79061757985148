<template>
  <div>
    <div class="p-8 pt-20">
      <a @click="goBack" class="mr-4 mb-4 block brand-nav-item cursor-pointer">
        <FontAwesomeIcon icon="chevron-left" />&nbsp;Back
      </a>
      <h1 class="font-bold uppercase mb-4 text-2xl inline-block">
        Device Info
      </h1>

      <EditDevice data-cy="edit-device-form" />
      <a
        @click="showDeleteModal(true)"
        class="cursor-pointer"
        data-cy="delete-device-link"
        >DELETE</a
      >
      <DeleteDevice v-if="deleteModal" @close="showDeleteModal(false)" />
      <DeviceTable />
      <LogsTable class="pt-20" :logs="selectedDevice[0].logs" />
      <ValidationsTable :validations="selectedDevice[0].validations" />
    </div>
  </div>
</template>

<script>
import DeviceTable from '@/components/devices/DeviceTable.vue';
import LogsTable from '@/components/logs/LogsTable.vue';
import ValidationsTable from '@/components/validations/ValidationsTable.vue';
import EditDevice from '@/components/devices/EditDevice.vue';
import DeleteDevice from '@/components/devices/DeleteDevice.vue';

export default {
  data () {
    return {
      deleteModal: false
    };
  },
  components: {
    DeviceTable,
    LogsTable,
    ValidationsTable,
    EditDevice,
    DeleteDevice
  },
  created () {
    this.$store.dispatch('getSelectedDeviceQuery', {
      deviceId: this.$route.params.deviceId
    });
  },
  computed: {
    selectedDevice () {
      return [this.$store.state.selectedDevice];
    }
  },
  methods: {
    showDeleteModal (bool) {
      this.deleteModal = bool;
    },
    goBack () {
      const prevPage = this.$route.query.prevPage || '/devices';
      this.$router.push({ path: prevPage });
    }
  }
};
</script>
<style scoped>
.modal[data-v-f08989e0] {
  top: 25%;
}
</style>
