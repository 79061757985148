var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"data-cy":"logs-table"}},[_c('p',{staticClass:"text-right"},[_vm._v(" Total Logs: "+_vm._s(_vm.logs.length)+" | Updated at: "+_vm._s(new Date().toLocaleString("en-US", { hour: "numeric", minute: "numeric", hour12: true }))+" ")]),_c('vue-good-table',{attrs:{"title":"Logs Table","columns":_vm.logColumns,"rows":_vm.logs,"pagination-options":{
      enabled: true,
      mode: 'pages',
      perPage: 100,
      position: 'both',
      perPageDropdown: [10, 50, 100, 250, 500, 1000, 10000],
      dropdownAllowAll: false,
      nextLabel: 'next',
      prevLabel: 'prev',
      rowsPerPageLabel: 'Rows per page',
      ofLabel: 'of',
      pageLabel: 'page',
      allLabel: 'All'
    },"sort-options":{
      enabled: true
    },"line-numbers":true},on:{"on-cell-click":_vm.onCellClick},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'deviceId')?_c('a',{attrs:{"href":"#"}},[_vm._v(" "+_vm._s(props.row.deviceId)+" ")]):(props.column.type === 'AWSDateTime')?_c('TwoDates',{attrs:{"date":props.row[props.column.field]}}):(props.column.field == 'message')?_c('Log',{attrs:{"rowData":props}}):_vm._e()]}}])},[_c('div',{staticClass:"emptystate",attrs:{"slot":"emptystate"},slot:"emptystate"},[_c('EmptyTable',{attrs:{"loading":_vm.$store.state.loading,"type":"Logs"}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }