<template>
  <div>
    <h2 class="text-center font-bold uppercase mb-4 text-2xl">
      {{ deviceType }}: {{ deviceId }}
    </h2>
    <div class="flex flex-wrap mb-14 justify-start">
      <div class="w-1/2">
        <p class="text-xs text-black">Status</p>
        <select v-model="status" data-cy="status" placeholder="FACTORY">
          <option
            v-bind:key="status.name"
            v-for="status in $store.getters.deviceStatuses"
            v-bind:value="status.name"
            >{{ status.name }}</option
          >
        </select>
      </div>
      <div class="w-1/2">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Message"
          placeholder='{"json":"example"}'
          v-model="message"
          data-cy="message"
          type="text"
        />
      </div>
    </div>
    <div class="text-center">
      <button @click="updateDevice" :disabled="loading" data-cy="update-device">
        <span v-if="loading">{{ loadingMessage }}</span>
        <span v-else>Update Device</span>
      </button>
    </div>
    <p data-cy="update-error" class="error" v-if="formError">{{ formError }}</p>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      deviceId: null,
      status: '',
      formError: null
    };
  },
  computed: {
    loading () {
      return this.$store.getters.loading;
    },
    loadingMessage () {
      return this.$store.getters.loadingStatusMessage;
    }
  },
  methods: {
    resetForm (data) {
      this.$store.dispatch('updateSelectedDevice', data);
      this.$store.dispatch('getDevicesQuery');
      this.$emit('refresh');
    },
    async updateDevice () {
      this.$store
        .dispatch('updateDeviceMutation', {
          input: {
            deviceId: this.deviceId,
            status: this.status,
            message: this.message
          }
        })
        .then((res) => {
          if (res.data) {
            this.formError = null;
            this.resetForm(res.data.updateDevice);
          } else {
            this.formError = res.errors[0].message;
          }
        })
        .catch(() => {
          this.formError = 'Sorry - There was an error. Please try again.';
        });
    }
  },
  created () {
    this.$store.dispatch('updateDeviceStatuses');

    if (
      this.$store.state.selectedDevice.deviceId !== this.$route.params.deviceId
    ) {
      this.$store.dispatch('setSelectedDevice', this.$route.params.deviceId);
    }
    const device = this.$store.state.selectedDevice;
    this.deviceType = device.deviceType;
    this.deviceId = device.deviceId;
    this.message = device.message;
    this.status = device.status;
  }
};
</script>

<style lang="scss">

.newFormDot {
  position: relative;
  margin-bottom: -30px;
  top: 35px;
  right: 160px;
}
</style>
