<template>
  <div class="inline-block">
    <SimpleModal @close="closeModal">
      <div class="text-center">
        <p class="mb-4">Are you sure you want to delete {{ device }}?</p>
        <button
          class="text-center"
          :disabled="loading"
          @click="deleteDevice"
          data-cy="do-it-btn"
        >
          <span v-if="loading">{{ loadingMessage }}</span>
          <span v-else>Do It</span>
        </button>
        <p class="error" v-if="formError">{{ formError }}</p>
      </div>
    </SimpleModal>
  </div>
</template>

<script>
import SimpleModal from '@/components/UI/SimpleModal.vue';

export default {
  data: function () {
    return {
      formError: ''
    };
  },
  components: {
    SimpleModal
  },
  computed: {
    loading () {
      return this.$store.getters.loading;
    },
    loadingMessage () {
      return this.$store.getters.loadingStatusMessage;
    },
    device () {
      return (
        this.$store.state.selectedDevice.deviceType +
        ' ' +
        this.$store.state.selectedDevice.deviceId
      );
    }
  },
  methods: {
    // at close, emits the event close passing false to showDeleteModal
    closeModal () {
      this.$emit('close', false);
    },
    async deleteDevice () {
      if (
        this.$route.path !== '/devices' &&
        this.$store.state.selectedDevice.deviceId !==
          this.$route.params.deviceId
      ) {
        this.$store.dispatch('setSelectedDevice', this.$route.params.deviceId);
      }
      if (this.$store.state.brand.includes('Gillette') && this.$store.state.selectedDevice.deviceType === 'HG2') {
        this.$store
          .dispatch('unclaimDeviceMutation', {
            deviceId: this.$store.state.selectedDevice.deviceId,
            deviceType: this.$store.state.selectedDevice.deviceType
          })
          .then(res => {
            if (res.data) {
              this.$store.dispatch('getDevicesQuery');
              this.selectedDevice = {};
              this.closeModal();
              this.$router.push('/devices');
            } else {
              this.formError = res.errors[0].message;
            }
          })
          .catch(() => {
            this.formError = 'Sorry - There was an error. Please try again.';
          });
      } else {
        this.$store
          .dispatch('deleteDeviceMutation', {
            deviceId: this.$store.state.selectedDevice.deviceId,
            deviceType: this.$store.state.selectedDevice.deviceType
          })
          .then(res => {
            if (res.data) {
              this.$store.dispatch('getDevicesQuery');
              this.selectedDevice = {};
              this.closeModal();
              this.$router.push('/devices');
            } else {
              this.formError = res.errors[0].message;
            }
          })
          .catch(() => {
            this.formError = 'Sorry - There was an error. Please try again.';
          });
      }
    }
  }
};
</script>
<style scoped>
.modal[data-v-f08989e0] {
  top: 25%;
}
</style>
