<template>
  <div data-cy="logs-table">
    <p class="text-right">
      Total Logs: {{ logs.length }} | Updated at:
      {{
      new Date().toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true
      })
      }}
    </p>
    <vue-good-table
      title="Logs Table"
      :columns="logColumns"
      :rows="logs"
      :pagination-options="{
        enabled: true,
        mode: 'pages',
        perPage: 100,
        position: 'both',
        perPageDropdown: [10, 50, 100, 250, 500, 1000, 10000],
        dropdownAllowAll: false,
        nextLabel: 'next',
        prevLabel: 'prev',
        rowsPerPageLabel: 'Rows per page',
        ofLabel: 'of',
        pageLabel: 'page',
        allLabel: 'All'
      }"
      :sort-options="{
        enabled: true
      }"
      :line-numbers="true"
      @on-cell-click="onCellClick"
    >
      <div slot="emptystate" class="emptystate">
        <EmptyTable :loading="$store.state.loading" type="Logs" />
      </div>
      <template slot="table-row" slot-scope="props">
        <a href="#" v-if="props.column.field === 'deviceId'">
          {{
          props.row.deviceId
          }}
        </a>
        <TwoDates
            v-else-if="props.column.type === 'AWSDateTime'"
            :date="props.row[props.column.field]"
          />
        <Log v-else-if="props.column.field == 'message'" :rowData="props"/>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import Log from '@/components/logs/Log.vue';
import EmptyTable from '@/components/UI/EmptyTable.vue';
import TwoDates from '@/components/UI/TwoDates.vue';

export default {
  components: {
    Log,
    EmptyTable,
    TwoDates
  },
  props: {
    logs: Array
  },
  data: function () {
    return {
      logColumns: this.$store.state.logsConfig.columns
    };
  },
  methods: {
    onCellClick: function (params) {
      if (params.column.field === 'deviceId') {
        const {
          row: { deviceId }
        } = params;

        this.$store.dispatch('getSelectedDeviceQuery', {
          deviceId: params.row.deviceId
        })
          .then((res) => {
            if (res.errors) {
              alert(res.errors[0].message);
            } else {
              this.$router.push({
                name: 'deviceDetail',
                params: { deviceId },
                query: { prevPage: '/devices' }
              });
            }
          });
      }
    }
  }
};
</script>
