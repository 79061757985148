<template>
  <div>
    <a href="#" v-if="showing" @click="toggleVis">
      Hide
    </a>
    <a href="#" v-else @click="toggleVis">
      Show
    </a>
    <slot v-if="showing" />
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      showing: false
    };
  },
  methods: {
    toggleVis: function () {
      this.showing = !this.showing;
    }
  }
};
</script>
