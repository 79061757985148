<template>
  <div data-cy="device-details-table">
    <vue-good-table
      :columns="columns"
      :rows="selectedDevice"
      :line-numbers="true"
    >
      <template slot="table-row" slot-scope="props">
        <div class="text-center" v-if="props.column.type == 'colorHex'">
            <ColorDot :colorHex="props.row[props.column.field]" :columnName="props.column.field"
            />
            <p>{{ props.row[props.column.field] }}</p>
          </div>
          <div class="text-center" v-else-if="props.column.type === 'colorArray'">
            <ColorDot :colorArray="props.row[props.column.field]" :columnName="props.column.field"
            />
            <p>{{ props.row[props.column.field] }}</p>
          </div>
        <div
          class="text-center"
          v-else-if="props.column.type === 'AWSDateTime'"
        >
          <TwoDates :date="props.row[props.column.field]" />
        </div>
        <div v-else-if="props.column.field === 'delete'">
          <button
            @click="showDeleteModal(true)"
            class="cursor-pointer"
            data-cy="delete-device-btn"
          >
            DELETE
          </button>
        </div>
      </template>
    </vue-good-table>
    <DeleteDevice v-if="deleteModal" @close="showDeleteModal(false)" />
  </div>
</template>

<script>
import brandConfig from '@/util/brandConfigs';
import currentBrand from '@/util/getCurrentBrand';
import ColorDot from '@/components/UI/ColorDot';
import TwoDates from '@/components/UI/TwoDates';
import DeleteDevice from '@/components/devices/DeleteDevice.vue';

export default {
  data: function () {
    return {
      columns: brandConfig[currentBrand()].device.columns,
      deleteModal: false
    };
  },
  components: {
    ColorDot,
    TwoDates,
    DeleteDevice
  },
  computed: {
    selectedDevice () {
      return [this.$store.state.selectedDevice];
    }
  },
  methods: {
    showDeleteModal (bool) {
      this.deleteModal = bool;
    }
  }
};
</script>
