<template>
  <div>
    <h2 class="text-center font-bold uppercase mb-4 text-2xl">
      {{ deviceType }}: #{{ deviceId }}
    </h2>
    <div class="flex flex-wrap mb-14 justify-start">
      <div class="w-1/2">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Mac Address"
          placeholder="address"
          v-model="macAddress"
          data-cy="macAddress"
          type="text"
        />
      </div>
      <div class="w-1/2 relative">
        <ColorDot :form="true" :colorHex="ringColor" />
        <BaseInput
          class="text-theme-darkest mr-4 ring-color"
          label="Ring Color"
          placeholder="#HEXCODE"
          v-model="ringColor"
          type="text"
        />
      </div>
      <div class="w-1/2">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="SSID"
          placeholder="ssid"
          v-model="ssid"
          data-cy="ssid"
          type="text"
        />
      </div>
      <div class="w-1/2">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Name"
          placeholder="name"
          v-model="name"
          data-cy="name"
          type="text"
        />
      </div>
      <div class="w-1/2">
        <p class="text-xs text-black">Status</p>
        <select data-cy="status" v-model="status" class="w-11/12">
          <option
            v-bind:key="status.name"
            v-for="status in $store.getters.deviceStatuses"
            v-bind:value="status.name"
            >{{ status.name }}</option
          >
        </select>
      </div>
      <div class="w-1/2">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Protocol Version"
          placeholder="protocol version"
          v-model.number="protocolVersion"
          type="number"
        />
      </div>
      <div class="w-1/2">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Firmware Version"
          placeholder="version"
          v-model="firmwareVersion"
          type="text"
        />
      </div>
      <div class="w-1/2">
        <p class="text-xs text-black">Voice Assistant Configured</p>
        <select
          data-cy="voiceAssistantConfigured"
          v-model="voiceAssistantConfigured"
          class="w-11/12"
        >
          <option
            v-bind:key="option.name"
            v-for="option in $store.getters.deviceVoiceConfigs"
            v-bind:value="option.name"
            >{{ option.name }}</option
          >
        </select>
      </div>
      <div class="w-1/2">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Brush Head Date"
          placeholder="date"
          v-model="brushHeadDate"
          type="string"
        />
      </div>
    </div>
    <div class="text-center">
      <button @click="updateDevice" :disabled="loading" data-cy="update-device">
        <span v-if="loading">{{ loadingMessage }}</span>
        <span v-else>Update Device</span>
      </button>
    </div>
    <p data-cy="update-error" class="error" v-if="formError">{{ formError }}</p>
  </div>
</template>

<script>
import ColorDot from '@/components/UI/ColorDot.vue';

export default {
  data: function () {
    return {
      deviceId: '',
      deviceType: '',
      ringColor: '',
      brushHeadDate: '',
      firmwareVersion: '',
      macAddress: '',
      name: '',
      protocolVersion: 0,
      ssid: '',
      status: '',
      voiceAssistantConfigured: '',
      formError: ''
    };
  },
  components: {
    ColorDot
  },
  computed: {
    loading () {
      return this.$store.getters.loading;
    },
    loadingMessage () {
      return this.$store.getters.loadingStatusMessage;
    }
  },
  methods: {
    resetForm () {
      const updatedDevice = {
        brushHeadDate: this.brushHeadDate,
        deviceId: this.deviceId,
        firmwareVersion: this.firmwareVersion,
        macAddress: this.macAddress,
        name: this.name,
        protocolVersion: this.protocolVersion,
        ringColor: this.ringColor,
        ssid: this.ssid,
        status: this.status,
        voiceAssistantConfigured: this.voiceAssistantConfigured,
        deviceType: this.$store.state.selectedDevice.deviceType,
        rssi: this.$store.state.selectedDevice.rssi
      };
      this.$store.dispatch('updateSelectedDevice', updatedDevice);
      this.$store.dispatch('getDevicesQuery');
      this.$emit('refresh');
    },
    async updateDevice () {
      this.$store
        .dispatch('updateDeviceMutation', {
          device: {
            deviceId: this.deviceId,
            ringColor: this.ringColor,
            brushHeadDate: this.brushHeadDate,
            firmwareVersion: this.firmwareVersion,
            macAddress: this.macAddress,
            name: this.name,
            protocolVersion: this.protocolVersion,
            ssid: this.ssid,
            status: this.status,
            voiceAssistantConfigured: this.voiceAssistantConfigured
          }
        })
        .then((res) => {
          if (res.data) {
            this.formError = '';
            this.resetForm();
          } else {
            this.formError = res.errors[0].message;
          }
        })
        .catch(() => {
          this.formError = 'Sorry - There was an error. Please try again.';
        });
    }
  },
  created () {
    this.$store.dispatch('updateDeviceStatuses');
    this.$store.dispatch('updateOralBVoiceConfigs');

    if (
      this.$store.state.selectedDevice.deviceId !== this.$route.params.deviceId
    ) {
      this.$store.dispatch('setSelectedDevice', this.$route.params.deviceId);
    }

    const device = this.$store.state.selectedDevice;
    this.deviceId = device.deviceId;
    this.deviceType = device.deviceType;
    this.ringColor = device.ringColor;
    this.brushHeadDate = device.brushHeadDate;
    this.firmwareVersion = device.firmwareVersion;
    this.macAddress = device.macAddress;
    this.name = device.name;
    this.protocolVersion = device.protocolVersion;
    this.ssid = device.ssid;
    this.status = device.status;
    this.voiceAssistantConfigured = device.voiceAssistantConfigured;
  }
};
</script>
