<template>
  <div>
    <div>
      <p class="text-right">
        Total Validations: {{ validations.length }} | Updated at:
        {{
          new Date().toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true
          })
        }}
      </p>
      <vue-good-table
        data-cy="validations-table"
        title="Validations Table"
        :columns="validationsColumns"
        :rows="validations"
        :sort-options="{
          enabled: true,
          initialSortBy: {field: 'time', type: 'desc'}
        }"
        :search-options="{
          enabled: true,
          initialSortBy: { field: 'time', type: 'desc' }
        }"
        :line-numbers="true"
        @on-cell-click="onCellClick"
      >
        <div slot="emptystate" class="emptystate" data-cy="empty-state">
          <EmptyTable :loading="$store.state.loading" type="Validations" />
        </div>
        <template slot="table-row" slot-scope="props">
          <a
            href="#"
            v-if="props.column.field === 'data.deviceId'"
            data-cy="device-detail-link"
            >{{ props.row.data.deviceId }}</a
          >
          <div v-else-if="props.column.type === 'id'" class="min-w-220">
              {{props.row[props.column.field]}}
          </div>
          <div v-else-if="props.column.type === 'number'" class="text-center">
              {{props.row[props.column.field]}}
          </div>
          <div v-else-if="props.column.field === 'delete'">
            <div v-if="sessionExists(props.row.data.sessionId)">
              <DeleteSession v-if="props.row.data.sessionId != ''" :session="{sessionId: props.row.data.sessionId}" />
            </div>
          </div>
          <TwoDates
            v-else-if="props.column.type === 'AWSDateTime'"
            :date="props.row[props.column.field]"
          />
        </template>
      </vue-good-table>
    </div>
  </div>
</template>

<script>
import TwoDates from '@/components/UI/TwoDates';
import EmptyTable from '@/components/UI/EmptyTable.vue';
import DeleteSession from '@/components/sessions/DeleteSession.vue';

export default {
  data () {
    return {
      validationsColumns: this.$store.state.validationsConfig.columns,
      pageTokens: [],
      currentPage: 1,
      FORWARD: 'forward',
      BACKWARD: 'backward',
      limit: 100,
      options: [10, 50, 100, 200, 500]
    };
  },
  components: {
    TwoDates,
    DeleteSession,
    EmptyTable
  },
  methods: {
    onCellClick: function (params) {
      const deviceId = params.row.data.deviceId;
      if (params.column.field === 'data.deviceId' && this.ownsDevice(params.row.data.deviceId)) {
        this.$store.dispatch('getSelectedDeviceQuery', {
          deviceId: params.row.data.deviceId
        })
          .then((res) => {
            if (res.errors) {
              alert(res.errors[0].message);
            } else {
              this.$router.push({
                name: 'deviceDetail',
                params: { deviceId },
                query: { prevPage: '/validations' }
              });
            }
          });
      }
    },
    ownsDevice (id) {
      const device = this.$store.state.devices.find(device => device.deviceId === id);
      return device ? true : alert('You no longer own this device');
    },
    sessionExists (id) {
      if (this.$store.state.sessions.length < 1) {
        // This lets the delete button show up if there is an error from the sessions query
        return true;
      }
      const session = this.$store.state.sessions.find(session => session.sessionId === id);
      return !!session;
    }
  },
  props: {
    validations: Array
  }
};
</script>
