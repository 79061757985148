<template>
  <div class="inline-block">
    <a
      class="ml-2 mr-2 cursor-pointer"
      @click="toggleModalEdit"
      data-cy="edit-device"
      >EDIT</a
    >
    <SimpleModal v-if="editModal" @close="editModal = false">
      <EditOralBDevice
        v-if="$store.state.brand.includes('Oral-B')"
        @refresh="toggleModalEdit()"
      />
      <EditGilletteDevice
        v-if="$store.state.brand.includes('Gillette')"
        @refresh="toggleModalEdit()"
      />
      <EditOpteDevice
        v-if="$store.state.brand === $store.state.brandConstants.opte"
        @refresh="toggleModalEdit()"
      />
      <EditLabsDevice
        v-if="$store.state.brand.includes('Labs')"
        @refresh="toggleModalEdit()"
      />
      <EditBraunDevice
        v-if="$store.state.brand.includes('Braun')"
        @refresh="toggleModalEdit()"
      />
    </SimpleModal>
  </div>
</template>

<script>
import SimpleModal from '@/components/UI/SimpleModal.vue';
import EditOralBDevice from '@/components/forms/oralB/EditOralBDevice.vue';
import EditOpteDevice from '@/components/forms/opte/EditOpteDevice.vue';
import EditGilletteDevice from '@/components/forms/gillette/EditGilletteDevice.vue';
import EditLabsDevice from '@/components/forms/labs/EditLabsDevice.vue';
import EditBraunDevice from '@/components/forms/braun/EditBraunDevice.vue';

export default {
  data: function () {
    return {
      editModal: false,
      formError: ''
    };
  },
  components: {
    SimpleModal,
    EditOralBDevice,
    EditOpteDevice,
    EditGilletteDevice,
    EditLabsDevice,
    EditBraunDevice
  },
  methods: {
    toggleModalEdit () {
      this.editModal = !this.editModal;
    }
  }
};
</script>
<style scoped>
.modal[data-v-f08989e0] {
  top: 25%;
}
</style>
