<template>
  <Revealer>
    <vue-json-pretty
      class="text-left"
      :data="JSON.parse(rowData.row.message)"
      :deep="1"
      showLength
      showLine
      showDoubleQuotes
      highlightMouseoverNode
    />
  </Revealer>
</template>

<script>
import Revealer from '@/components/UI/Revealer.vue';
export default {
  components: {
    Revealer
  },
  props: ['rowData']
};
</script>
