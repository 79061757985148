import { render, staticRenderFns } from "./ColorDot.vue?vue&type=template&id=44202696&"
import script from "./ColorDot.vue?vue&type=script&lang=js&"
export * from "./ColorDot.vue?vue&type=script&lang=js&"
import style0 from "./ColorDot.vue?vue&type=style&index=0&id=44202696&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports