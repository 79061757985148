<template>
  <div>
    <h2 class="text-center font-bold uppercase mb-4 text-2xl">
      {{ deviceType }}: #{{ deviceId }}
    </h2>
    <div class="flex flex-wrap mb-14 justify-start">
      <div class="w-1/2">
        <ColorDot
          v-bind:class="{ newFormDot: baseConnectColor }"
          :colorArray="baseConnectColor"
          :columnName="'baseConnectColor'"
        />
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Base Connect Color"
          placeholder="ex: #000000"
          v-model="baseConnectColor"
          type="text"
        />
      </div>

      <div class="w-1/2">
        <ColorDot
          v-bind:class="{ newFormDot: baseDockedColor }"
          :colorArray="baseDockedColor"
          :columnName="'baseDockedColor'"
        />
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Base Docked Color"
          placeholder="ex: #000000"
          v-model="baseDockedColor"
          type="text"
        />
      </div>
      <div class="w-1/2">
        <ColorDot
          v-bind:class="{ newFormDot: baseDownloadColor }"
          :colorArray="baseDownloadColor"
          :columnName="'baseDownloadColor'"
        />
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Base Download Color"
          placeholder="ex: #000000"
          v-model="baseDownloadColor"
          type="text"
        />
      </div>
      <div class="w-1/2">
        <ColorDot
          v-bind:class="{ newFormDot: baseReadyColor }"
          :colorArray="baseReadyColor"
          :columnName="'baseReadyColor'"
        />
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Base Ready Color"
          placeholder="ex: #000000"
          v-model="baseReadyColor"
          type="text"
        />
      </div>
      <div class="w-1/2">
        <ColorDot
          v-bind:class="{ newFormDot: baseUploadColor }"
          :colorArray="baseUploadColor"
          :columnName="'baseUploadColor'"
        />
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Base Upload Color"
          placeholder="ex: #000000"
          v-model="baseUploadColor"
          type="text"
        />
      </div>
      <div class="w-1/2">
        <ColorDot
          v-bind:class="{ newFormDot: handlePressureHighColor }"
          :colorArray="handlePressureHighColor"
          :columnName="'handlePressureHighColor'"
        />
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Handle Pressure High Color"
          placeholder="ex: #000000"
          v-model="handlePressureHighColor"
          type="text"
        />
      </div>

      <div class="w-1/2">
        <ColorDot
          v-bind:class="{ newFormDot: handlePressureLowColor }"
          :colorArray="handlePressureLowColor"
          :columnName="'handlePressureLowColor'"
        />
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Handle Pressure Low Color"
          placeholder="ex: #000000"
          v-model="handlePressureLowColor"
          type="text"
        />
      </div>

      <div class="w-1/2">
        <ColorDot
          v-bind:class="{ newFormDot: handlePressureMedColor }"
          :colorArray="handlePressureMedColor"
          :columnName="'handlePressureMedColor'"
        />
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Handle Pressure Med Color"
          placeholder="ex: #000000"
          v-model="handlePressureMedColor"
          type="text"
        />
      </div>
      <div class="w-1/2">
        <ColorDot
          v-bind:class="{ newFormDot: handleChargingColor }"
          :colorArray="handleChargingColor"
          :columnName="'handleChargingColor'"
        />
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Handle Charging Color"
          placeholder="ex: #000000"
          v-model.number="handleChargingColor"
          type="text"
        />
      </div>
      <div class="w-1/2">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Base Firmware Version"
          placeholder=""
          v-model="baseFirmwareVersion"
          type="text"
        />
      </div>
      <div class="w-1/2">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Cell Band"
          placeholder=""
          v-model="cellBand"
          type="text"
        />
      </div>

      <div class="w-1/2">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Cell Strength"
          placeholder=""
          v-model.number="cellStrength"
          type="text"
        />
      </div>

      <div class="w-1/2">
        <p class="text-xs text-black">Firmware Mode</p>
        <select v-model="firmwareMode" class="w-11/12">
          <option
            v-bind:key="firmwareMode.name"
            v-for="firmwareMode in $store.getters.deviceFirmwareModeTypes"
            v-bind:value="firmwareMode.name"
            >{{ firmwareMode.name }}</option
          >
        </select>
      </div>
      <div class="w-1/2">
        <p class="text-xs text-black">Handle Docked</p>
        <select v-model="handleDocked" data-cy="handleDocked" class="w-11/12">
          <option
            v-bind:key="handleDocked.name"
            v-for="handleDocked in $store.getters.deviceHandleDockedTypes"
            v-bind:value="handleDocked.name"
            >{{ handleDocked.name }}</option
          >
        </select>
      </div>
      <div class="w-1/2">
        <ColorDot
          v-bind:class="{ newFormDot: handleChargingColor }"
          :colorArray="handleDockedColor"
          :columnName="'handleDockedColor'"
        />
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Handle Docked Color"
          placeholder="ex: #000000"
          v-model.number="handleDockedColor"
          type="text"
        />
      </div>
      <div class="w-1/2">
        <ColorDot
          v-bind:class="{ newFormDot: handleChargingColor }"
          :colorArray="handleDownloadColor"
          :columnName="'handleDownloadColor'"
        />
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Handle Download Color"
          placeholder="ex: #000000"
          v-model="handleDownloadColor"
          type="text"
        />
      </div>
      <div class="w-1/2">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Handle Firmware Version"
          placeholder=""
          v-model="handleFirmwareVersion"
          type="text"
        />
      </div>
      <div class="w-1/2">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Handle Id"
          placeholder=""
          v-model="handleId"
          type="text"
        />
      </div>
      <div class="w-1/2">
        <p class="text-xs text-black">Handle Mode</p>
        <select v-model="handleMode" data-cy="handleMode" class="w-11/12">
          <option
            v-bind:key="handleMode.name"
            v-for="handleMode in $store.getters.deviceHandleModeTypes"
            v-bind:value="handleMode.name"
            >{{ handleMode.name }}</option
          >
        </select>
      </div>

      <div class="w-1/2">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Last Cartridge Replacement"
          placeholder=""
          v-model="lastCartridgeReplacement"
          type="text"
        />
      </div>

      <div class="w-1/2">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Pressure Baseline"
          placeholder=""
          v-model="pressureBaseline"
          type="text"
        />
      </div>

      <div class="w-1/2">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Pressure Hysteresis"
          placeholder=""
          v-model="pressureHysteresis"
          type="text"
        />
      </div>

      <div class="w-1/2">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Pressure Threshold 1"
          placeholder=""
          v-model="pressureThreshold1"
          type="text"
        />
      </div>

      <div class="w-1/2">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Pressure Threshold 2"
          placeholder=""
          v-model="pressureThreshold2"
          type="text"
        />
      </div>
      <div class="w-1/2">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Pressure Threshold Stroke"
          placeholder=""
          v-model="pressureThresholdStroke"
          type="text"
        />
      </div>

      <div class="w-1/2">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Sensor Range Acceleration"
          placeholder=""
          v-model.number="sensorRangeAcceleration"
          type="text"
        />
      </div>

      <div class="w-1/2">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Sensor Range Gyro"
          placeholder=""
          v-model.number="sensorRangeGyro"
          type="text"
        />
      </div>

      <div class="w-1/2">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Sensor Range Mag"
          placeholder=""
          v-model.number="sensorRangeMag"
          type="text"
        />
      </div>
      <div class="w-1/2">
        <p class="text-xs text-black">Status</p>
        <select v-model="status" data-cy="status" class="w-11/12">
          <option
            v-bind:key="status.name"
            v-for="status in $store.getters.deviceStatuses"
            v-bind:value="status.name"
            >{{ status.name }}</option
          >
        </select>
      </div>
    </div>
    <div class="text-center">
      <button @click="updateDevice" :disabled="loading" data-cy="update-device">
        <span v-if="loading">{{ loadingMessage }}</span>
        <span v-else>Update Device</span>
      </button>
    </div>
    <p data-cy="update-error" class="error" v-if="formError">{{ formError }}</p>
  </div>
</template>

<script>
import ColorDot from '@/components/UI/ColorDot';
export default {
  data: function () {
    return {
      deviceType: '',
      deviceId: '',
      status: '',
      baseConnectColor: '',
      baseDockedColor: '',
      baseDownloadColor: '',
      baseFirmwareVersion: '',
      baseReadyColor: '',
      baseUploadColor: '',
      cellBand: '',
      cellStrength: '',
      firmwareMode: '',
      handleChargingColor: '',
      handleDocked: '',
      handleDockedColor: '',
      handleDownloadColor: '',
      handleFirmwareVersion: '',
      handleId: '',
      handleMode: '',
      handlePressureHighColor: '',
      handlePressureLowColor: '',
      handlePressureMedColor: '',
      lastCartridgeReplacement: '',
      pressureBaseline: '',
      pressureHysteresis: '',
      pressureThreshold1: '',
      pressureThreshold2: '',
      pressureThresholdStroke: '',
      sensorRangeAcceleration: '',
      sensorRangeGyro: '',
      sensorRangeMag: '',
      formError: ''
    };
  },
  components: {
    ColorDot
  },
  computed: {
    loading () {
      return this.$store.getters.loading;
    },
    loadingMessage () {
      return this.$store.getters.loadingStatusMessage;
    }
  },
  methods: {
    resetForm (data) {
      this.$store.dispatch('updateSelectedDevice', data);
      this.$store.dispatch('getDevicesQuery');
      this.$emit('refresh');
    },
    async updateDevice () {
      this.$store
        .dispatch('updateDeviceMutation', {
          device: {
            baseConnectColor: this.baseConnectColor,
            baseDockedColor: this.baseDockedColor,
            baseDownloadColor: this.baseDownloadColor,
            baseFirmwareVersion: this.baseFirmwareVersion,
            baseReadyColor: this.baseReadyColor,
            baseUploadColor: this.baseUploadColor,
            cellBand: this.cellBand,
            cellStrength: this.cellStrength,
            deviceId: this.deviceId,
            firmwareMode: this.firmwareMode,
            handleChargingColor: this.handleChargingColor,
            handleDocked: this.handleDocked,
            handleDockedColor: this.handleDockedColor,
            handleDownloadColor: this.handleDownloadColor,
            handleFirmwareVersion: this.handleFirmwareVersion,
            handleId: this.handleId,
            handleMode: this.handleMode,
            handlePressureHighColor: this.handlePressureHighColor,
            handlePressureLowColor: this.handlePressureLowColor,
            handlePressureMedColor: this.handlePressureMedColor,
            lastCartridgeReplacement: this.lastCartridgeReplacement,
            pressureBaseline: this.pressureBaseline,
            pressureHysteresis: this.pressureHysteresis,
            pressureThreshold1: this.pressureThreshold1,
            pressureThreshold2: this.pressureThreshold2,
            pressureThresholdStroke: this.pressureThresholdStroke,
            sensorRangeAcceleration: this.sensorRangeAcceleration,
            sensorRangeGyro: this.sensorRangeGyro,
            sensorRangeMag: this.sensorRangeMag,
            status: this.status
          }
        })
        .then((res) => {
          if (res.data) {
            this.formError = '';
            this.resetForm(res.data.updateDevice);
          } else {
            this.formError = res.errors[0].message;
          }
        })
        .catch(() => {
          this.formError = 'Sorry - There was an error. Please try again.';
        });
    }
  },
  created () {
    this.$store.dispatch('updateDeviceTypes');
    this.$store.dispatch('updateDeviceStatuses');
    this.$store.dispatch('updateGilletteFirmwareMode');
    this.$store.dispatch('updateGilletteHandleDocked');
    this.$store.dispatch('updateGilletteHandleMode');
    this.$store.dispatch('updateGilletteBaseCartridgeLifeIndicator');
    if (
      this.$store.state.selectedDevice.deviceId !== this.$route.params.deviceId
    ) {
      this.$store.dispatch('setSelectedDevice', this.$route.params.deviceId);
    }

    const device = this.$store.state.selectedDevice;
    this.deviceId = device.deviceId;
    this.deviceType = device.deviceType;
    this.firmwareMode = device.firmwareMode;
    this.handleMode = device.handleMode;
    this.status = device.status;
    this.baseConnectColor = device.baseConnectColor;
    this.baseDockedColor = device.baseDockedColor;
    this.baseDownloadColor = device.baseDownloadColor;
    this.baseFirmwareVersion = device.baseFirmwareVersion;
    this.baseReadyColor = device.baseReadyColor;
    this.baseUploadColor = device.baseUploadColor;
    this.cellBand = device.cellBand;
    this.cellStrength = device.cellStrength;
    this.handleChargingColor = device.handleChargingColor;
    this.handleDocked = device.handleDocked || 'NO';
    this.handleDockedColor = device.handleDockedColor;
    this.handleDownloadColor = device.handleDownloadColor;
    this.handleFirmwareVersion = device.handleFirmwareVersion;
    this.handleId = device.handleId;
    this.handlePressureHighColor = device.handlePressureHighColor;
    this.handlePressureLowColor = device.handlePressureLowColor;
    this.handlePressureMedColor = device.handlePressureMedColor;
    this.lastCartridgeReplacement = device.lastCartridgeReplacement;
    this.pressureBaseline = device.pressureBaseline;
    this.pressureHysteresis = device.pressureHysteresis;
    this.pressureThreshold1 = device.pressureThreshold1;
    this.pressureThreshold2 = device.pressureThreshold2;
    this.pressureThresholdStroke = device.pressureThresholdStroke;
    this.sensorRangeAcceleration = device.sensorRangeAcceleration;
    this.sensorRangeGyro = device.sensorRangeGyro;
    this.sensorRangeMag = device.sensorRangeMag;
  }
};
</script>

<style lang="scss">

.newFormDot {
  position: relative;
  margin-bottom: -30px;
  top: 35px;
  right: 160px;
}
</style>
