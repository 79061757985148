<template>
  <div class="relative">
    <h2 class="text-center font-bold uppercase mb-4 text-2xl">
      Update Opte Device
    </h2>
    <div class="flex flex-wrap mb-14 justify-start">
      <div class="w-1/3">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Ab Timeout Grace"
          placeholder="Ab Timeout Grace"
          v-model.number="abTimeoutGrace"
          data-cy="abTimeoutGrace"
          type="text"
        />
      </div>
      <div class="w-1/3">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Ab Timeout Idle"
          placeholder="Ab Timeout Idle"
          v-model.number="abTimeoutIdle"
          type="text"
        />
      </div>
      <div class="w-1/3">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Ab Timeout Silence"
          placeholder="Ab Timeout Silence"
          v-model.number="abTimeoutSilence"
          type="text"
        />
      </div>
      <div class="w-1/3">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Analog Gain"
          placeholder="Analog Gain"
          v-model="analogGain"
          type="text"
        />
      </div>
      <div class="w-1/3">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="App Offset X"
          placeholder="App Offset X"
          v-model.number="appOffsetX"
          type="text"
        />
      </div>
      <div class="w-1/3">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="App Offset Y"
          placeholder="App Offset Y"
          v-model.number="appOffsetY"
          type="text"
        />
      </div>
      <div class="w-1/3">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Auto Et Enable"
          placeholder="Auto Et Enable"
          v-model.number="autoEtEnable"
          type="text"
        />
      </div>
      <div class="w-1/3">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Base Bootcount"
          placeholder="Base Bootcount"
          v-model.number="baseBootcount"
          type="text"
        />
      </div>
      <div class="w-1/3">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Base Bootcount"
          placeholder="Base Bootcount"
          v-model.number="baseFirmwareBuildOptions"
          type="text"
        />
      </div>
      <div class="w-1/3">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Base Firmware Flag"
          placeholder="Base Firmware Flag"
          v-model="baseFirmwareFlag"
          type="text"
        />
      </div>
      <div class="w-1/3">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Base Firmware Version"
          placeholder="Base Firmware Version"
          v-model="baseFirmwareVersion"
          type="text"
        />
      </div>

      <div class="w-1/3">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Base Hardware Model"
          placeholder="Base Hardware Model"
          v-model="baseHardwareModel"
          type="text"
        />
      </div>

      <div class="w-1/3">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Base Hardware Version"
          placeholder="Base Hardware Version"
          v-model="baseHardwareVersion"
          type="text"
        />
      </div>
      <div class="w-1/3">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Base Uptime"
          placeholder="Base Uptime"
          v-model="baseUptime"
          type="text"
        />
      </div>

      <div class="w-1/3">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Blue Led Level"
          placeholder="Blue Led Level"
          v-model="blueLedLevel"
          type="text"
        />
      </div>

      <div class="w-1/3">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Camera Offset Horz"
          placeholder="Camera Offset Horz"
          v-model.number="cameraOffsetHorz"
          type="text"
        />
      </div>

      <div class="w-1/3">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Camera Offset Vert"
          placeholder="Camera Offset Vert"
          v-model.number="cameraOffsetVert"
          type="text"
        />
      </div>

      <div class="w-1/3">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Camera Rotation"
          placeholder="Camera Rotation"
          v-model.number="cameraRotation"
          type="text"
        />
      </div>

      <div class="w-1/3">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Camera Rotational Angle"
          placeholder="Camera Rotational Angle"
          v-model="cameraRotationalAngle"
          type="text"
        />
      </div>
      <div class="w-1/3">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Cart Drops Kms In Lifetime"
          placeholder="Cart Drops Kms In Lifetime"
          v-model.number="cartDropsKmsInLifetime"
          type="text"
        />
      </div>
      <div class="w-1/3">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Cart Drops Kms Out Lifetime"
          placeholder="Cart Drops Kms Out Lifetime"
          v-model.number="cartDropsKmsOutLifetime"
          type="text"
        />
      </div>
      <div class="w-1/3">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Cart Drops Print Lifetime"
          placeholder="Cart Drops Print Lifetime"
          v-model.number="cartDropsPrintLifetime"
          type="text"
        />
      </div>

      <div class="w-1/3">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Cart Drops Purge Lifetime"
          placeholder="Cart Drops Purge Lifetime"
          v-model.number="cartDropsPurgeLifetime"
          type="text"
        />
      </div>
      <div class="w-1/3">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Cart Id"
          placeholder="Cart Id"
          v-model="cartId"
          type="text"
        />
      </div>
      <div class="w-1/3">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Cart Time On Skin Lifetime"
          placeholder="Cart Time On Skin Lifetime"
          v-model.number="cartTimeOnSkinLifetime"
          type="text"
        />
      </div>
      <div class="w-1/3">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Display Inversion State"
          placeholder="Display Inversion State"
          v-model.number="displayInversionState"
          type="text"
        />
      </div>
      <div class="w-1/3">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Drops Per Click"
          placeholder="Drops Per Click"
          v-model.number="dropsPerClick"
          type="text"
        />
      </div>
      <div class="w-1/3">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Heater Timeout"
          placeholder="Heater Timeout"
          v-model="heaterTimeout"
          type="text"
        />
      </div>
      <div class="w-1/3">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Idle Timeout"
          placeholder="Idle Timeout"
          v-model.number="idleTimeout"
          type="text"
        />
      </div>
      <div class="w-1/3">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Image Height"
          placeholder="Image Height"
          v-model.number="imageHeight"
          type="text"
        />
      </div>
      <div class="w-1/3">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Image Width"
          placeholder="Image Width"
          v-model.number="imageWidth"
          type="text"
        />
      </div>
      <div class="w-1/3">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Intensity"
          placeholder="Intensity"
          v-model="intensity"
          type="text"
        />
      </div>
      <div class="w-1/3">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Kws Freq Off Skin"
          placeholder="Kws Freq Off Skin"
          v-model="kwsFreqOffSkin"
          type="text"
        />
      </div>

      <div class="w-1/3">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Min Drops"
          placeholder="Min Drops"
          v-model="maxDrops"
          type="text"
        />
      </div>
      <div class="w-1/3">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Min Drops"
          placeholder="Min Drops"
          v-model.number="minDrops"
          type="text"
        />
      </div>
      <div class="w-1/3">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Min Drops"
          placeholder="Min Drops"
          v-model="nozzlePrefireDelta"
          type="text"
        />
      </div>
      <div class="w-1/3">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Min Drops"
          placeholder="Min Drops"
          v-model="nozzlePrefireWidth"
          type="text"
        />
      </div>

      <div class="w-1/3">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Nozzle Pulse Delta"
          placeholder="Nozzle Pulse Delta"
          v-model="nozzlePulseDelta"
          type="text"
        />
      </div>
      <div class="w-1/3">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Nozzle Pulse Width"
          placeholder="Nozzle Pulse Width"
          v-model="nozzlePulseWidth"
          type="text"
        />
      </div>

      <div class="w-1/3">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Ntp Server"
          placeholder="Ntp Server"
          v-model="ntpServer"
          type="text"
        />
      </div>

      <div class="w-1/3">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Recieved Time"
          placeholder="Recieved Time"
          v-model="receivedTime"
          type="text"
        />
      </div>

      <div class="w-1/3">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Roi Height"
          placeholder="Roi Height"
          v-model.number="roiHeight"
          type="text"
        />
      </div>

      <div class="w-1/3">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Roi Total Width"
          placeholder="Roi Total Width"
          v-model.number="roiTotalWidth"
          type="text"
        />
      </div>

      <div class="w-1/3">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Roller Change"
          placeholder="Roller Change"
          v-model="rollerChange"
          type="text"
        />
      </div>

      <div class="w-1/3">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Roller Window Offset X"
          placeholder="Roller Window Offset X"
          v-model.number="rollerWindowOffsetX"
          type="text"
        />
      </div>

      <div class="w-1/3">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Roller Window Offset Y"
          placeholder="Roller Window Offset Y"
          v-model.number="rollerWindowOffsetY"
          type="text"
        />
      </div>

      <div class="w-1/3">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Roller Window Height"
          placeholder="Roller Window Height"
          v-model.number="rollerWindowHeight"
          type="text"
        />
      </div>

      <div class="w-1/3">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Roller Window Width"
          placeholder="Roller Window Width"
          v-model.number="rollerWindowWidth"
          type="text"
        />
      </div>
      <div class="w-1/3">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Ror Height"
          placeholder="Ror Height"
          v-model.number="rorHeight"
          type="text"
        />
      </div>

      <div class="w-1/3">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Rssi"
          placeholder="Rssi"
          v-model.number="rssi"
          type="text"
        />
      </div>

      <div class="w-1/3">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Rssi"
          placeholder="Rssi"
          v-model.number="rssi"
          type="text"
        />
      </div>

      <div class="w-1/3">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Skin Row Start"
          placeholder="Skin Row Start"
          v-model.number="skinRowStart"
          type="text"
        />
      </div>

      <div class="w-1/3">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Temp Shutdown High"
          placeholder="Temp Shutdown High"
          v-model.number="tempCutoffHigh"
          type="text"
        />
      </div>
      <div class="w-1/3">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Temp Cutoff Lowt"
          placeholder="Temp Cutoff Low"
          v-model.number="tempCutoffLow"
          type="text"
        />
      </div>
      <div class="w-1/3">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Temp Shutdown Low"
          placeholder="Temp Shutdown Low"
          v-model.number="tempShutdownLow"
          type="text"
        />
      </div>
      <div class="w-1/3">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Threshold"
          placeholder="Threshold"
          v-model="threshold"
          type="text"
        />
      </div>
      <div class="w-1/3">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Timezone"
          placeholder="Timezone"
          v-model.number="timezone"
          type="text"
        />
      </div>
      <div class="w-1/3">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Wand Bootcount"
          placeholder="Wand Bootcount"
          v-model.number="wandBootcount"
          type="text"
        />
      </div>
      <div class="w-1/3">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Wand Firmware Flag"
          placeholder="Wand Firmware Flag"
          v-model="wandFirmwareBuildOptions"
          type="text"
        />
      </div>

      <div class="w-1/3">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Skin Row Start"
          placeholder="Skin Row Start"
          v-model="wandFirmwareFlag"
          type="text"
        />
      </div>

      <div class="w-1/3">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Wand Firmware Version"
          placeholder="Wand Firmware Version"
          v-model="wandFirmwareVersion"
          type="text"
        />
      </div>

      <div class="w-1/3">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Wand Hardware Model"
          placeholder="Wand Hardware Model"
          v-model="wandHardwareModel"
          type="text"
        />
      </div>

      <div class="w-1/3">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Wand Hardware Version"
          placeholder="Wand Hardware Version"
          v-model="wandHardwareVersion"
          type="text"
        />
      </div>

      <div class="w-1/3">
        <BaseInput
          class="text-theme-darkest mr-4"
          label="Wand Uptime"
          placeholder="Wand Uptime"
          v-model.number="wandUptime"
          type="text"
        />
      </div>
    </div>
    <div class="text-center">
      <button @click="updateDevice" :disabled="loading" data-cy="update-device">
        <span v-if="loading">{{ loadingMessage }}</span>
        <span v-else>Update Device</span>
      </button>
    </div>
    <p data-cy="update-error" class="error" v-if="formError">{{ formError }}</p>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      abTimeoutGrace: '',
      abTimeoutIdle: '',
      abTimeoutSilence: '',
      analogGain: '',
      appOffsetX: '',
      appOffsetY: '',
      autoEtEnable: '',
      baseBootcount: '',
      baseFirmwareBuildOptions: '',
      baseFirmwareFlag: '',
      baseFirmwareVersion: '',
      baseHardwareModel: '',
      baseHardwareVersion: '',
      baseUptime: '',
      blueLedLevel: '',
      cameraOffsetHorz: '',
      cameraOffsetVert: '',
      cameraRotation: '',
      cameraRotationalAngle: '',
      cartDropsKmsInLifetime: '',
      cartDropsKmsOutLifetime: '',
      cartDropsPrintLifetime: '',
      cartDropsPurgeLifetime: '',
      cartId: '',
      cartTimeOnSkinLifetime: '',
      deviceId: '',
      deviceType: '',
      displayInversionState: '',
      dropsPerClick: '',
      heaterTimeout: '',
      idleTimeout: '',
      imageHeight: '',
      imageWidth: '',
      intensity: '',
      kwsFreqOffSkin: '',
      maxDrops: '',
      minDrops: '',
      nozzlePrefireDelta: '',
      nozzlePrefireWidth: '',
      nozzlePulseDelta: '',
      nozzlePulseWidth: '',
      ntpServer: '',
      receivedTime: '',
      roiHeight: '',
      roiTotalWidth: '',
      rollerChange: '',
      rollerWindowHeight: '',
      rollerWindowOffsetX: '',
      rollerWindowOffsetY: '',
      rollerWindowWidth: '',
      rorHeight: '',
      rssi: '',
      skinRowStart: '',
      tempCutoffHigh: '',
      tempCutoffLow: '',
      tempShutdownHigh: '',
      tempShutdownLow: '',
      thingName: '',
      threshold: '',
      timezone: '',
      wandBootcount: '',
      wandFirmwareBuildOptions: '',
      wandFirmwareFlag: '',
      wandFirmwareVersion: '',
      wandHardwareModel: '',
      wandHardwareVersion: '',
      wandUptime: '',
      wifiFirmwareVersion: '',
      statusOptions: [{ name: 'factory' }, { name: 'provisioned' }],
      voiceAssistantOptions: ['DISABLED', 'ENABLED'],
      formError: ''
    };
  },
  computed: {
    loading () {
      return this.$store.getters.loading;
    },
    loadingMessage () {
      return this.$store.getters.loadingStatusMessage;
    }
  },
  methods: {
    resetForm () {
      const updatedDevice = {
        abTimeoutGrace: this.abTimeoutGrace,
        abTimeoutIdle: this.abTimeoutIdle,
        abTimeoutSilence: this.abTimeoutSilence,
        analogGain: this.analogGain,
        appOffsetX: this.appOffsetX,
        appOffsetY: this.appOffsetY,
        autoEtEnable: this.autoEtEnable,
        baseBootcount: this.baseBootcount,
        baseFirmwareBuildOptions: this.baseFirmwareBuildOptions,
        baseFirmwareFlag: this.baseFirmwareFlag,
        baseFirmwareVersion: this.baseFirmwareVersion,
        baseHardwareModel: this.baseHardwareModel,
        baseHardwareVersion: this.baseHardwareVersion,
        baseUptime: this.baseUptime,
        blueLedLevel: this.blueLedLevel,
        cameraOffsetHorz: this.cameraOffsetHorz,
        cameraOffsetVert: this.cameraOffsetVert,
        cameraRotation: this.cameraRotation,
        cameraRotationalAngle: this.cameraRotationalAngle,
        cartDropsKmsInLifetime: this.cartDropsKmsInLifetime,
        cartDropsKmsOutLifetime: this.cartDropsKmsOutLifetime,
        cartDropsPrintLifetime: this.cartDropsPrintLifetime,
        cartDropsPurgeLifetime: this.cartDropsPurgeLifetime,
        cartId: this.cartId,
        cartTimeOnSkinLifetime: this.cartTimeOnSkinLifetime,
        deviceId: this.deviceId,
        deviceType: this.deviceType,
        displayInversionState: this.displayInversionState,
        dropsPerClick: this.dropsPerClick,
        heaterTimeout: this.heaterTimeout,
        idleTimeout: this.idleTimeout,
        imageHeight: this.imageHeight,
        imageWidth: this.imageWidth,
        intensity: this.intensity,
        kwsFreqOffSkin: this.kwsFreqOffSkin,
        maxDrops: this.maxDrops,
        minDrops: this.minDrops,
        nozzlePrefireDelta: this.nozzlePrefireDelta,
        nozzlePrefireWidth: this.nozzlePrefireWidth,
        nozzlePulseDelta: this.nozzlePulseDelta,
        nozzlePulseWidth: this.nozzlePulseWidth,
        ntpServer: this.ntpServer,
        receivedTime: this.receivedTime,
        roiHeight: this.roiHeight,
        roiTotalWidth: this.roiTotalWidth,
        rollerChange: this.rollerChange,
        rollerWindowHeight: this.rollerWindowHeight,
        rollerWindowOffsetX: this.rollerWindowOffsetX,
        rollerWindowOffsetY: this.rollerWindowOffsetY,
        rollerWindowWidth: this.rollerWindowWidth,
        rorHeight: this.rorHeight,
        rssi: this.rssi,
        skinRowStart: this.skinRowStart,
        tempCutoffHigh: this.tempCutoffHigh,
        tempCutoffLow: this.tempCutoffLow,
        tempShutdownHigh: this.tempShutdownHigh,
        tempShutdownLow: this.tempShutdownLow,
        thingName: this.thingName,
        threshold: this.threshold,
        timezone: this.timezone,
        wandBootcount: this.wandBootcount,
        wandFirmwareBuildOptions: this.wandFirmwareBuildOptions,
        wandFirmwareFlag: this.wandFirmwareFlag,
        wandFirmwareVersion: this.wandFirmwareVersion,
        wandHardwareModel: this.wandHardwareModel,
        wandHardwareVersion: this.wandHardwareVersion,
        wandUptime: this.wandUptime,
        wifiFirmwareVersion: this.wifiFirmwareVersion
      };
      this.$store.dispatch('updateSelectedDevice', updatedDevice);
      this.$emit('refresh');
    },
    async updateDevice () {
      this.$store
        .dispatch('updateDeviceMutation', {
          device: {
            abTimeoutGrace: this.abTimeoutGrace,
            abTimeoutIdle: this.abTimeoutIdle,
            abTimeoutSilence: this.abTimeoutSilence,
            analogGain: this.analogGain,
            appOffsetX: this.appOffsetX,
            appOffsetY: this.appOffsetY,
            autoEtEnable: this.autoEtEnable,
            baseBootcount: this.baseBootcount,
            baseFirmwareBuildOptions: this.baseFirmwareBuildOptions,
            baseFirmwareFlag: this.baseFirmwareFlag,
            baseFirmwareVersion: this.baseFirmwareVersion,
            baseHardwareModel: this.baseHardwareModel,
            baseHardwareVersion: this.baseHardwareVersion,
            baseUptime: this.baseUptime,
            blueLedLevel: this.blueLedLevel,
            cameraOffsetHorz: this.cameraOffsetHorz,
            cameraOffsetVert: this.cameraOffsetVert,
            cameraRotation: this.cameraRotation,
            cameraRotationalAngle: this.cameraRotationalAngle,
            cartDropsKmsInLifetime: this.cartDropsKmsInLifetime,
            cartDropsKmsOutLifetime: this.cartDropsKmsOutLifetime,
            cartDropsPrintLifetime: this.cartDropsPrintLifetime,
            cartDropsPurgeLifetime: this.cartDropsPurgeLifetime,
            cartId: this.cartId,
            cartTimeOnSkinLifetime: this.cartTimeOnSkinLifetime,
            deviceId: this.deviceId,
            deviceType: this.deviceType,
            displayInversionState: this.displayInversionState,
            dropsPerClick: this.dropsPerClick,
            heaterTimeout: this.heaterTimeout,
            idleTimeout: this.idleTimeout,
            imageHeight: this.imageHeight,
            imageWidth: this.imageWidth,
            intensity: this.intensity,
            kwsFreqOffSkin: this.kwsFreqOffSkin,
            maxDrops: this.maxDrops,
            minDrops: this.minDrops,
            nozzlePrefireDelta: this.nozzlePrefireDelta,
            nozzlePrefireWidth: this.nozzlePrefireWidth,
            nozzlePulseDelta: this.nozzlePulseDelta,
            nozzlePulseWidth: this.nozzlePulseWidth,
            ntpServer: this.ntpServer,
            receivedTime: this.receivedTime,
            roiHeight: this.roiHeight,
            roiTotalWidth: this.roiTotalWidth,
            rollerChange: this.rollerChange,
            rollerWindowHeight: this.rollerWindowHeight,
            rollerWindowOffsetX: this.rollerWindowOffsetX,
            rollerWindowOffsetY: this.rollerWindowOffsetY,
            rollerWindowWidth: this.rollerWindowWidth,
            rorHeight: this.rorHeight,
            rssi: this.rssi,
            skinRowStart: this.skinRowStart,
            tempCutoffHigh: this.tempCutoffHigh,
            tempCutoffLow: this.tempCutoffLow,
            tempShutdownHigh: this.tempShutdownHigh,
            tempShutdownLow: this.tempShutdownLow,
            thingName: this.thingName,
            threshold: this.threshold,
            timezone: this.timezone,
            wandBootcount: this.wandBootcount,
            wandFirmwareBuildOptions: this.wandFirmwareBuildOptions,
            wandFirmwareFlag: this.wandFirmwareFlag,
            wandFirmwareVersion: this.wandFirmwareVersion,
            wandHardwareModel: this.wandHardwareModel,
            wandHardwareVersion: this.wandHardwareVersion,
            wandUptime: this.wandUptime,
            wifiFirmwareVersion: this.wifiFirmwareVersion
          }
        })
        .then((res) => {
          if (res.data) {
            this.formError = '';
            this.resetForm();
          } else {
            this.formError = res.errors[0].message;
          }
        })
        .catch(() => {
          this.formError = 'Sorry - There was an error. Please try again.';
        });
    }
  },
  created () {
    if (
      this.$store.state.selectedDevice.deviceId !== this.$route.params.deviceId
    ) {
      this.$store.dispatch('setSelectedDevice', this.$route.params.deviceId);
    }

    const device = this.$store.state.selectedDevice;
    this.abTimeoutGrace = device.abTimeoutGrace;
    this.abTimeoutIdle = device.abTimeoutIdle;
    this.abTimeoutSilence = device.abTimeoutSilence;
    this.analogGain = device.analogGain;
    this.appOffsetX = device.appOffsetX;
    this.appOffsetY = device.appOffsetY;
    this.autoEtEnable = device.autoEtEnable;
    this.baseBootcount = device.baseBootcount;
    this.baseFirmwareBuildOptions = device.baseFirmwareBuildOptions;
    this.baseFirmwareFlag = device.baseFirmwareFlag;
    this.baseFirmwareVersion = device.baseFirmwareVersion;
    this.baseHardwareModel = device.baseHardwareModel;
    this.baseHardwareVersion = device.baseHardwareVersion;
    this.baseUptime = device.baseUptime;
    this.blueLedLevel = device.blueLedLevel;
    this.cameraOffsetHorz = device.cameraOffsetHorz;
    this.cameraOffsetVert = device.cameraOffsetVert;
    this.cameraRotation = device.cameraRotation;
    this.cameraRotationalAngle = device.cameraRotationalAngle;
    this.cartDropsKmsInLifetime = device.cartDropsKmsInLifetime;
    this.cartDropsKmsOutLifetime = device.cartDropsKmsOutLifetime;
    this.cartDropsPrintLifetime = device.cartDropsPrintLifetime;
    this.cartDropsPurgeLifetime = device.cartDropsPurgeLifetime;
    this.cartId = device.cartId;
    this.cartTimeOnSkinLifetime = device.cartTimeOnSkinLifetime;
    this.deviceId = device.deviceId;
    this.deviceType = device.deviceType;
    this.displayInversionState = device.displayInversionState;
    this.dropsPerClick = device.dropsPerClick;
    this.heaterTimeout = device.heaterTimeout;
    this.idleTimeout = device.idleTimeout;
    this.imageHeight = device.imageHeight;
    this.imageWidth = device.imageWidth;
    this.intensity = device.intensity;
    this.kwsFreqOffSkin = device.kwsFreqOffSkin;
    this.maxDrops = device.maxDrops;
    this.minDrops = device.minDrops;
    this.nozzlePrefireDelta = device.nozzlePrefireDelta;
    this.nozzlePrefireWidth = device.nozzlePrefireWidth;
    this.nozzlePulseDelta = device.nozzlePulseDelta;
    this.nozzlePulseWidth = device.nozzlePulseWidth;
    this.ntpServer = device.ntpServer;
    this.receivedTime = device.receivedTime;
    this.roiHeight = device.roiHeight;
    this.roiTotalWidth = device.roiTotalWidth;
    this.rollerChange = device.rollerChange;
    this.rollerWindowHeight = device.rollerWindowHeight;
    this.rollerWindowOffsetX = device.rollerWindowOffsetX;
    this.rollerWindowOffsetY = device.rollerWindowOffsetY;
    this.rollerWindowWidth = device.rollerWindowWidth;
    this.rorHeight = device.rorHeight;
    this.rssi = device.rssi;
    this.skinRowStart = device.skinRowStart;
    this.tempCutoffHigh = device.tempCutoffHigh;
    this.tempCutoffLow = device.tempCutoffLow;
    this.tempShutdownHigh = device.tempShutdownHigh;
    this.tempShutdownLow = device.tempShutdownLow;
    this.thingName = device.thingName;
    this.threshold = device.threshold;
    this.timezone = device.timezone;
    this.wandBootcount = device.wandBootcount;
    this.wandFirmwareBuildOptions = device.wandFirmwareBuildOptions;
    this.wandFirmwareFlag = device.wandFirmwareFlag;
    this.wandFirmwareVersion = device.wandFirmwareVersion;
    this.wandHardwareModel = device.wandHardwareModel;
    this.wandHardwareVersion = device.wandHardwareVersion;
    this.wandUptime = device.wandUptime;
    this.wifiFirmwareVersion = device.wifiFirmwareVersion;
  }
};
</script>
